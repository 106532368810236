import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {css} from "@emotion/core";
import styled from "@emotion/styled";
import Container from "react-bootstrap/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const AppComingSoonSection3Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  margin-bottom: 50px;
  @media(max-width:768px) {
    margin-bottom: 50px;
  }@media(min-width:992px){
    padding-top:100px;
  }
  @media(max-width:991px){
    padding-top: 0px;
  }
`

const AppComingSoonSection3Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom:15px;
  color: ${props => props.theme.colors.gray_dark};
  @media(max-width:1199px){
    font: 56px/60px Abril Fatface;
  }
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:17.5px;
  }
`

const TextContent = styled.div`
  font: 20px/22px Roboto;
  color: ${props => props.theme.colors.gray_dark};
`

export default function AppComingSoonSection3(props) {
  return (
  <AppComingSoonSection3Background backgroundColor={props.Background} color={props.TextColor}>
      <Container>
          <Col>
            <Row>
                <Col lg="5" css={css`@media(min-width:1200px){
                      margin-top:150px;
                    }@media(min-width:992px){
                      margin-top:100px;
                    }
                    @media(max-width:991px){
                      padding-top: 0px;
                    }`} >
                    <Row css={css`
                      @media(max-width:991px){
                        justify-content:center;
                      }`}>
                        <AppComingSoonSection3Title>
                            Ready, Match, Grow!
                        </AppComingSoonSection3Title>
                    </Row>
                    <Row css={css`
                      @media(max-width:991px){
                        justify-content:center;
                      }`}>
                        <TextContent>Get the app and join the community now</TextContent>
                    </Row>
                    <Row css={css`margin-top:31px;@media(max-width:991px){justify-content:center;}`}>
                    <OutboundLink href="https://apps.apple.com/us/app/womenworks/id1538858709" target="_blank" rel="noreferrer">
                        <img alt="WomenWorks - WomenWorks App Questionnaire" src="/appstore-badge.png" 
                            css={css`
                                width:178px;
                                height:57px;
                                margin-right:20px;
                                @media(max-width:768px){
                                    margin-bottom: 20px;
                                    margin-right:0px;
                                }
                            `}
                        />
                    </OutboundLink>
                    <OutboundLink href="https://play.google.com/store/apps/details?id=io.womenworks.app" target="_blank" rel="noreferrer">
                        <img alt="WomenWorks - WomenWorks App Questionnaire" src="/google-play-badge.png" css={css`width:178px;height:57px`}/>
                    </OutboundLink>
                    </Row>
                </Col>
                <Col lg="1"></Col>
                <Col lg="6" css={css`
                      @media(max-width:991px){
                        justify-content:center;
                        display:flex;
                      }`}>
                    <img alt="WomenWorks - We Go Girls! App" src="/page-app-coming-soon-section3-img1.png"
                        css={css`
                        @media(max-width:768px) {
                            height: 300px;
                            width: 85vw;
                            object-fit: contain;
                            margin-top: 30px;
                        }
                        @media(max-width:991px) {
                            height: 434px;
                            max-width: 500px;
                            object-fit: contain;
                            margin-top: 30px;
                        }
                        @media(min-width:992px) {
                            height: 534px;
                            max-width: 50vw;
                            object-fit: cover;
                        }
                        @media(min-width:1199px) {
                          height: 868px;
                          max-width: 47vw;
                          object-fit: contain;
                        }
                        `}
                    />
                </Col>
            </Row>
          </Col>
      </Container>
  </AppComingSoonSection3Background>
  )
}

